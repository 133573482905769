<template>
  <v-app-bar app fixed flat class="df-b-c bg-white">
    <div style="width: 96px">
      <v-btn v-if="page.back" icon @click="goBack">
        <img src="../assets/icon/back.png" class="height-28" />
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <div>
      <v-btn icon :href="toHttp(portfolio.website)" target="_blank">
        <img :src="portfolio.logo" class="height-28" />
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <div style="width: 96px; text-align: right;">
      <v-btn v-if="showPhoneBtn" icon :href="portfolioTelephone">
        <v-icon>phone</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
import { Tool, DataType } from '../js/core'

const tool = new Tool()
export default {
  name: 'AppToolbar',
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showBack: true,
      showPhoneBtn: true
    }
  },
  computed: {
    portfolio: {
      get () {
        return this.$store.state.application.portfolioJson
      }
    },
    portfolioTelephone: {
      get () {
        return 'tel:' + this.$store.state.application.portfolioJson.telephone
      }
    },
    page: {
      get () {
        return this.$store.state.application.currentPage
      }
    },
    locationUrl () {
      return this.$route.path
    }
  },
  watch: {
    'store.getters.getFromLA': {
      handler (val) {
        this.showPhoneBtn = !val
      },
      immediate: true,
      deep: true
    },
    // 监听当前url，若包含错误页则不显示回退按钮
    locationUrl: {
      handler: function (val, oldVal) {
        if (val.includes('notification')) {
          this.page.back = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    toHttp (url) {
      if (url) {
        if (!url.includes('https://')) {
          return 'https://' + url
        } else {
          return url
        }
      }
    },
    goBack () {
      if (this.isPc) {
        this.$router.push(DataType.PAGES[this.page.previous].addressPC)
      } else {
        this.$router.push(DataType.PAGES[this.page.previous].address)
      }
    }
  },
  created () {
    if (tool.isEmpty(this.$store.state.application.portfolioJson) && tool.isNotEmpty(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))) {
      this.$store.commit('setPortfolioJson', JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO)))
    }
  }
}
</script>

<style lang="sass" scoped>
.bg-white
  background-color: #fff !important
</style>
